<template>
  <main class="page-my-profile page-change-my-password">
    <PageTitle centered>Cambia password</PageTitle>
    <div class="container">
      <div class="row">
        <ProfilePasswordChangeForm class="col-md-6 offset-md-3" :title="null" />
      </div>
    </div>
  </main>
</template>

<script>

export default {
  name: 'my-profile-change-password',
  components: {
    PageTitle: () => import('../../components/Layout/PageTitle'),
    ProfilePasswordChangeForm: () => import('../../components/Account/ProfilePasswordChangeForm'),
  },
};

</script>
